import { ref, computed, watch } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import ability from '@/libs/acl/ability'
import sip from '@/store/sip'
import { debounce } from 'lodash'


export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('iotcomms_master_account/getAll')

  const tableColumns = [
    { label: 'sessionId', key: 'sessionId', sortable: false },
    { label: 'sip.view.calling_party_number', key: 'callingPartyNumber', sortable: false, tdClass: 'text-truncate router', thStyle: 'max-width: 150px;'},
    { label: 'sip.view.remote_party_number', key: 'remotePartyNumber', sortable: false },
    { label: 'alarm.title', key: 'alarm.id', sortable: false },
    { label: 'sip.view.state', key: 'state', sortable: false },
    { label: 'ivr', key: 'ivr', sortable: false },
    { label: 'sip.view.account', key: 'device_interphone.iotcomms_master_account.name', sortable: false },
    {
      label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned'
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned'
    }
  ]


if (ability.can('read_record_call', 'SipCall') == true ) {
  tableColumns.splice(10, 0, { label: 'sip.view.recording', key: 'recording' })
}

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const ivrFilter = ref('1');

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })


  const iotcommsOptions = computed(() => store.getters['iotcomms_master_account/getSelectedItems'])
  const iotcommsFilter = ref(null)

  const stateOptions = computed(() => [{value:'terminated', label:'terminated'},
  {value:'waiting', label:'waiting'},
  {value:'precall', label:'precall'},
  {value:'dropped', label:'dropped'},
  {value:'transfer', label:'transfer'}])

  const stateFilter = ref(null)
  const sipCalls = ref(null)

  const fetchList = (ctx, callback) => {
    store.dispatch('sip_call/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_iotcomms_master_account: iotcommsFilter.value,
      state: stateFilter.value,
      ivr: ivrFilter.value,
      with: ['alarm', 'device_interphone']
    })
    .then(response => {
      callback(response.data)
      totalList.value = response.meta.total
      sipCalls.value = response.data
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error obteniendo la información',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ iotcommsFilter, ivrFilter, stateFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    iotcommsFilter,
    iotcommsOptions,
    ivrFilter,
    stateFilter,
    stateOptions,
    refetchData,
    sipCalls
  }
}
