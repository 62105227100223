<template>
  <div>
    <!-- Filters -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <filters
      :iotcomms-filter.sync="iotcommsFilter"
      :iotcomms-options="iotcommsOptions"
      :state-filter.sync="stateFilter"
      :state-options="stateOptions"
      :ivr-filter.sync="ivrFilter"
    />

    <toastification-loading-content ref="loadingToast" />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #cell(recording)="data">
          <audio
            controls
            v-if="data.item.recording"
            :controlsList="
              $can('download_record_call', 'SipCall')
                ? 'download'
                : 'nodownload'
            "
            v-bind="recordings"
          >
            <source
              v-if="
                recordings.find(
                  (recording) => recording.id === data.item.sessionId
                )
              "
              :src="
                recordings.find(
                  (recording) => recording.id === data.item.sessionId
                ).url
              "
            />
            Your browser does not support the audio element.
          </audio>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="openEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.delete") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item>
              <router-link
                :to="{ name: 'plane', params: { id: data.item.id } }"
              >
                <feather-icon icon="MapIcon" />
                <span class="align-middle ml-50">planos</span>
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch } from "@vue/composition-api";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import store from "@/store";
import Filters from "./Filters.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Filters,
    Breadcrumb,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // Breadcrumb
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.voip.calls");

    const loadingToast = ref();
    const toast = useToast();

    const { t } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };

    const buttonExport = ref(false);
    const recordings = ref([]);

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      iotcommsFilter,
      iotcommsOptions,
      ivrFilter,
      stateOptions,
      stateFilter,
      sipCalls,
    } = useList();

    const exportExcel = () => {
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("sip_call/getAll", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_iotcomms_master_account: iotcommsFilter.value,
          state: stateFilter.value,
          ivr: ivrFilter.value,
          with: ["alarm", "device_interphone"],
           action: "export",
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };

    const getRecording = (sessionId) => {
      store
        .dispatch("sip/getRecording", {
          sessionId: sessionId,
        })
        .then((response) => {
          const url = URL.createObjectURL(response);
          const recording = { id: sessionId, url: url };
          recordings.value.push(recording);
        })
        .catch((e) => {
          console.log({ e });
        });
    };

    watch([sipCalls], () => {
      sipCalls.value.forEach((sipCall) => {
        if (sipCall.recording) {
          getRecording(sipCall.sessionId);
        }
      });
    });

    return {
      // Breadcrumb
      nameNav,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,

      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      buttonExport,
      loadingToast,
      iotcommsFilter,
      iotcommsOptions,
      ivrFilter,
      stateOptions,
      stateFilter,
      exportExcel,
      getRecording,
      sipCalls,
      recordings,
      formatDateAssigned
    };
  },
};
</script>



<style lang="scss">
.router {
  max-width: 500px;
}
.dropzone-file {
  padding: 0px;
  margin: auto;
  border: none;
  max-width: 250px;
  min-height: 200px;
  display: flex;
  box-shadow: inset 0 0 0 3px #dfdfdf;
}
.dropzone {
  .dz-preview {
    margin: 0px;
    width: 100%;
  }
  .dz-message {
    text-align: center;
    margin: auto;
  }
}
.scroll {
  width: 100%;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #1b3d5e;
}
</style>
