<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $tc("actions.filter", 2) }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row align-v="center" class="d-flex align-items-center">
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $tc("iotcomms_master_account.title", 2) }}
          </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="iotcommsFilter"
            :options="iotcommsOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:iotcommsFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $tc("sip.view.state", 1) }}
          </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="stateFilter"
            :options="stateOptions"
            multiple
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:stateFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="d-flex align-items-center mt-2">
          <b-form-checkbox
            id="filter-ivr"
            name="filter-ivr"
            @change="(val) => $emit('update:ivrFilter', val)"
            :checked="ivrFilter"
            value="1"
            unchecked-value="0"
            class="my-1 text-capitalize"
            switch
          >
            IVR
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    iotcommsOptions: {
      type: Array,
      default: null,
    },
    iotcommsFilter: {
      type: Array,
      default: null,
    },
    stateOptions: {
      type: Array,
      default: null,
    },
    stateFilter: {
      type: Array,
      default: null,
    },
    ivrFilter: {
      type: String,
      default: "1",
    },
  },
};
</script>